import { useState } from 'react';
import { useServices } from '../../services/useServices';

export const useLogin = () => {
  const errorMessages = {
    wrongPassword: 'Սխալ էլ․ հասցե կամ գաղտնաբառ',
    serverError: 'Տեղի է ունեցել սխալ',
  };
  const [isError, setIsError] = useState({ show: false, message: '' });
  const [showPassword, setShowPassword] = useState(true);
  const { login } = useServices();
  const passwordShow = () => {
    setShowPassword((prev) => !prev);
  };
  const handeLogin = async (values) => {
    await login(values).catch((error) => {
      if (error.response?.status) {
        if (error.response?.status === 422) {
          setIsError({
            show: true,
            message: errorMessages.wrongPassword,
          });

          setTimeout(() => {
            setIsError({
              show: false,
              message: '',
            });
          }, 3000);
        }
      } else {
        setIsError({
          show: true,
          message: errorMessages.serverError,
        });
      }
    });
  };

  return {
    showPassword,
    passwordShow,
    handeLogin,
    isError,
  };
};
