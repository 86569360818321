/* eslint-disable no-console */
import { Table } from 'antd';
import React from 'react';
import styles from './Orders.module.scss';
import { useOrders } from './useOrders';

const Orders = () => {
  const {
    OrderColumns,
    shouldShowPagination,
    handlePageChange,
    pageSize,
    currentPage,
    orders: { count, ordersData },
  } = useOrders();

  return (
    <div className={styles.blogs}>
      <div className={styles.pageHeader}>
        <h1>Պատվերներ</h1>
      </div>
      <div>
        <div className="defaultTable ordersTable">
          <Table
            dataSource={ordersData || []}
            columns={OrderColumns}
            rowKey="id"
            pagination={
              shouldShowPagination
                ? {
                    total: count,
                    pageSize,
                    current: currentPage,
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                    onChange: handlePageChange,
                  }
                : false
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Orders;
