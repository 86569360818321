import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactQuill from 'react-quill';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import styles from './CustomEditor.module.scss';

const CustomEditor = ({ initialNames, blog = false, tabGerman = false }) => {
  const quillRef = useRef(null);
  const { id } = useParams();

  return (
    <div className={styles.customEditorWrapper}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div>
            <p />
            <label htmlFor={initialNames.title} className="label">
              {id
                ? `Փոփոխել Վերնագիրը ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Վերնագիր ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.title} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => {
                return (
                  <>
                    <input name={name} type="text" onChange={onChange(name)} value={value} />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                );
              }}
            </Field>
          </div>
        </Col>
        <Col span={24}>
          <div>
            <p />
            <label htmlFor={initialNames.url} className="label">
              {id
                ? `change slug ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `slug ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.url} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => {
                return (
                  <>
                    <input name={name} type="text" onChange={onChange(name)} value={value} />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                );
              }}
            </Field>
          </div>
        </Col>
        <Col span={24}>
          <label className="label" htmlFor={initialNames.content}>
            {id
              ? `Փոփոխել պարունակությունը ${tabGerman ? '(Գերմաներեն)' : '*'}`
              : `Պարունակություն ${tabGerman ? '(Գերմաներեն)' : '*'} `}
          </label>
          <Field name={initialNames.content}>
            {({ field: { onChange, name }, meta: { error, touched, value } }) => (
              <>
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  onChange={onChange(name)}
                  value={value}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }, 'custom'],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['image', 'video'],
                    ],
                  }}
                />
                {error && touched && <span className="error-msg">{error}</span>}
              </>
            )}
          </Field>
        </Col>
        {blog && (
          <Col span={24}>
            <label className="label">
              {' '}
              {id
                ? `Փոփոխել կատեգորիաները ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Ավելացնել կատեգորիաներ ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.category} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => {
                return (
                  <>
                    <input name={name} type="text" onChange={onChange(name)} value={value} />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                );
              }}
            </Field>
          </Col>
        )}
        {blog && (
          <Col span={24}>
            <label className="label">
              {' '}
              {id
                ? `Փոփոխել ներածությունը ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Ներածություն ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.text} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => {
                return (
                  <>
                    <textarea
                      name={name}
                      onChange={onChange(name)}
                      value={value}
                      className="textarea"
                    />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                );
              }}
            </Field>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CustomEditor;

CustomEditor.propTypes = {
  initialNames: PropTypes.object,
  blog: PropTypes.bool,
  tabGerman: PropTypes.bool,
};
